import React from 'react';
import Navigation from './components/Nav';
import CLeftArrow from './components/CLeftArrow';
import { Typography, Avatar, Button } from 'antd';
import Footer from './components/Footer';
import 'antd/dist/antd.css';
import './App';

const {Title} = Typography;
//const { TextArea } = Input;

const MainLeft ={
    borderRadius: '50%',
    fontSize: '30px',
}

const MainRight = {
    width: '70%',
    margin: 'auto'
}
var profile = require('./images/profile.jpg');

class Contact extends React.Component {
    render(){
        return (
            <div className="Contact-bck">
                 <Navigation/>
                 <CLeftArrow/>
                 <Footer/>
                 <div>
                    <section className="MainFlex">
                        <div style={MainLeft}>
                                <Avatar size={128} src={profile} icon="user"  />
                        </div>
                        <div style={MainRight}>
                                <div className="HeaDer">
                                    <Title className="header1">Let's get in touch</Title>
                                </div>
                                <p id="text">Feel free to contact me with any questions, or if you wish to know more about what I do. Please allow me 1-2 business day to reply to you; 
                                but please be assured that I will reply as soon as I possibly can. Thank you, for visiting my site.</p>  
                        </div>
                        <Button type="primary" icon="mail" size="large"><a href="mailto:steven.ly369@gmail.com" style={{color: 'white'}}> Contact</a></Button>
                    </section>
                 </div>
            </div>    
        );
    }
}


export default Contact;