import React from 'react';
import './App';
import Navigation from './components/Nav';
import ALeftArrow from './components/ALeftArrow';
import ARightArrow from './components/ARightArrow';
import Footer from './components/Footer';
import 'antd/dist/antd.css';
import { Typography, Steps, Row, Col, Timeline } from 'antd';

const { Title } = Typography;
const { Step } = Steps;
var html = require('./images/html5-with-wordmark-color.svg'); 
var css = require('./images/css3-seeklogo.com.svg'); 
var javascript = require('./images/javascript-js-seeklogo.com.svg'); 
var express = require('./images/express-seeklogo.com.svg'); 
var angular = require('./images/angular-icon-seeklogo.svg'); 
var node = require('./images/node-node-js-seeklogo.com.svg'); 
var postgresql = require('./images/postgresql.svg');
var react = require('./images/react.svg');

class About extends React.Component {
    render(){
        return (
            <div className="About-bck">
                 <Navigation/>
                 <ALeftArrow/>
                 <ARightArrow/>
                 <Footer/>
                 <div className="ATop">
                    <Title className="header1">About Me</Title>
                    <p>Below is my current education timeline, while it is not very impressive. I enjoy programming and it motivates me 
                        to learn more everyday.  </p>
                    <Steps id="step" style={{padding: '20px', borderRadius: '12px'}}  progressDot current={7}>
                        <Step title="2010" description="Graduated From East Kentwood High School" />
                        <Step title="2012" description="Blunder Years Of Grand Rapids Community College" />
                        <Step title="2014" description="Started Process Of Mechanical Engineer UnderGrad" />
                        <Step title="2017" description="Changed Majors To Computer Science" />
                        <Step title="2019" description="Accepted In Detroit Grand Circus Front-End Development Program" />
                        <Step title="2019" description="Finished program at Grand Circus." />
                        <Step title="2020" description="Accepted In Epicodus Development Program" />
                        <Step title="Current" description="Process of finishing Epicodus and looking for opportunities " />
                    </Steps>
                    {/* Mobile only for steps */}
                    <div id="time">
                    <Timeline mode="alternate">
                            <Timeline.Item>2000: Enrolled in bootcamp</Timeline.Item>
                            <Timeline.Item>2012: Blunder Years Of College</Timeline.Item>
                            <Timeline.Item>2017: Started Process Of Mechanical Engineer UnderGrad</Timeline.Item>
                            <Timeline.Item>2019: Changed Majors To Computer Science</Timeline.Item>
                            <Timeline.Item>2019: Accepted In Detroit Grand Circus Front-End Program</Timeline.Item>
                            <Timeline.Item>2019: Finished program at Grand Circus.</Timeline.Item>
                            <Timeline.Item>2020: Accepted In Epicodus Development Program</Timeline.Item>
                            <Timeline.Item>Current: Process of finishing Epicodus and looking for opportunities</Timeline.Item>
                        </Timeline>
                    </div>
                 </div>
                 <div className="ABottom">
                    <Title className="header1">Technologies Used</Title>
                    <div>
                    <Row id="BCol" gutter={24}>
                            <Col className="column" xs={24} sm={12} md={6} lg={6} xl={3}>
                                <img className="line" src={html} height="80" width="80" alt="HTML" />
                            </Col>
                            <Col className="column" xs={24} sm={12} md={6} lg={6} xl={3}>
                                <img className="line" src={css} height="80" width="80" alt="css" />
                            </Col>
                            <Col className="column" xs={24} sm={12} md={6} lg={6} xl={3}>
                                <img className="line" src={javascript} height="80" width="80" alt="javascript" />
                            </Col>
                            <Col className="column" xs={24} sm={12} md={6} lg={6} xl={3}>
                                <img className="line" src={angular} height="80" width="80" alt="css" />
                            </Col>
                            <Col className="column" xs={24} sm={12} md={6} lg={6} xl={3}>
                                <img className="line" src={react} height="80" width="80" alt="npm" />
                            </Col>
                            
                            <Col className="column" xs={24} sm={12} md={6} lg={6} xl={3}>
                                <img className="line" src={postgresql} height="80" width="80" alt="HTML" />
                            </Col>
                            <Col className="column" xs={24} sm={12} md={6} lg={6} xl={3}>
                                <img className="line" src={express} height="80" width="80" alt="javascript" />
                            </Col>
                            <Col className="column" xs={24} sm={12} md={6} lg={6} xl={3}>
                                <img className="line" src={node} height="80" width="80" alt="npm" />
                            </Col>
                        </Row>
                    </div>
                 </div>
            </div>    
        );
    }
}

export default About;