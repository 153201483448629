import React from 'react';
import Navigation from './components/Nav';
import PLeftArrow from './components/PLeftArrow';
import PRightArrow from './components/PRightArrow';
import Footer from './components/Footer';
import { Typography, Row, Col, Tag, Button } from 'antd';

const { Title } = Typography;
var screen1 = require('./images/Screenshot1.png');
var moneybudget = require('./images/moneybudget.png');
var movieapi = require('./images/angularproject.png');
var stardew = require('./images/stardew.png');
const btn = {
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'center'
}

class About extends React.Component {

    state = {
        loading: false,
        iconLoading: false,
      };
    
      enterLoading = () => {
        this.setState({ loading: true });
      };
    
      enterIconLoading = () => {
        this.setState({ iconLoading: true });
      };

    render(){
        return (
            <div className="Portfolio-bck">
                 <Navigation/>
                 <PLeftArrow/>
                 <PRightArrow/>
                 <Footer/>
                 <div class="Port-top">
                    <div className="head-container">
                        <Title className="header1">My Project Portfolio</Title>
                    </div>
                    <p>Below are my projects that I have helped created with the help of fellow cohorts.</p>
                 </div>
                 <div className="Port-bottom">
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <figure className="img-wrapper">
                                <img className="main-image" src={screen1} height="200" width="400" alt="Portfolio 1" />
                                <figcaption className="capt">
                                    <Title style={{color: "white"}} level={3}>Boogie-Battle</Title>
                                    <Tag color="#45d">Angular</Tag>
                                    <Tag color="#45d">Socket.IO</Tag>
                                    <div className="btn" style={btn}>
                                        <Button type="default" icon="github" ghost onClick={this.enterIconLoading} loading={this.state.iconLoading}>
                                            <a href="javascript:void(0);" target="_blank" rel="noopener noreferrer">Github</a>
                                        </Button>
                                        <Button type="default" icon="search">
                                            <a href="http://boogie-battle.herokuapp.com" target="_blank" rel="noopener noreferrer">Website</a>
                                        </Button>
                                    </div>
                                </figcaption>
                                <div className="overlay"></div>
                            </figure>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <figure className="img-wrapper">
                                <img className="main-image" src={movieapi} height="200" width="400" alt="Portfolio 1" />
                                <figcaption className="capt">
                                    <Title style={{color: "white"}} level={3}>Movie Api App</Title>
                                    <Tag color="#45d">Angular</Tag>
                                    <Tag color="#45d">IMDb API</Tag>
                                    <div className="btn" style={btn}>
                                        <Button type="default" icon="github" ghost onClick={this.enterIconLoading} loading={this.state.iconLoading}>
                                            <a href="https://github.com/StevenLy369/movieapi" target="_blank" rel="noopener noreferrer">Github</a>
                                        </Button>
                                        <Button type="default" icon="search">
                                            <a href="https://agitated-shaw-d370a9.netlify.com/" target="_blank" rel="noopener noreferrer">Website</a>
                                        </Button>
                                    </div>
                                </figcaption>
                                <div className="overlay"></div>
                            </figure>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <figure className="img-wrapper">
                                <img className="main-image" src={moneybudget} height="200" width="400" alt="Portfolio 1" />
                                <figcaption className="capt">
                                    <Title style={{color: "white"}} level={3}>Money Budget</Title>
                                    <Tag color="#45d">Javascript</Tag>
                                    <Tag color="#45d">Google API</Tag>
                                    <div className="btn" style={btn}>
                                        <Button type="default" icon="github" ghost onClick={this.enterIconLoading} loading={this.state.iconLoading}>
                                            <a href="https://github.com/StevenLy369/BudgetProject" target="_blank" rel="noopener noreferrer">Github</a>
                                        </Button>
                                        <Button type="default" icon="search">
                                            <a href="https://stevenly369.com/BudgetProject/" target="_blank" rel="noopener noreferrer">Website</a>
                                        </Button>
                                    </div>
                                </figcaption>
                                <div className="overlay"></div>
                            </figure>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <figure className="img-wrapper">
                                <img className="main-image" src={stardew} height="200" width="400" alt="Portfolio 1" />
                                <figcaption className="capt">
                                    <Title style={{color: "white"}} level={3}>Stardew Harvest</Title>
                                    <Tag color="#45d">Phaser.io</Tag>
                                    <Tag color="#45d">Javascript</Tag>
                                    <div className="btn" style={btn}>
                                        <Button type="default" icon="github" ghost onClick={this.enterIconLoading} loading={this.state.iconLoading}>
                                            <a href="https://github.com/StevenLy369/stardew_harvest_crossing" target="_blank" rel="noopener noreferrer">Github</a>
                                        </Button>
                                        <Button type="default" icon="search">
                                            <a href="https://ecstatic-franklin-edce6b.netlify.app/" target="_blank" rel="noopener noreferrer">Website</a>
                                        </Button>
                                    </div>
                                </figcaption>
                                <div className="overlay"></div>
                            </figure>
                        </Col>
                    </Row>
                 </div>
            </div>    
        );
    }
}

export default About;