import React from 'react';
import { Avatar, Button, Typography } from 'antd';
import 'antd/dist/antd.css';
import '../App';


const MainLeft ={
     borderRadius: '50%',
     fontSize: '30px',
     padding: '20px',
     margin: 'auto'
}

const {Title} = Typography;

const MainRight = {
     width: '70%',
     margin: 'auto'
}
var profile = require('../images/profile.jpg');

class MainText extends React.Component {
     render(){
     return(
          <section className="MainFlex" style={{transform: 'unset', top: '20%'}}>
               <div style={MainLeft}>
                    <Avatar size={128} src={profile} icon="user"  />
               </div>
               <div style={MainRight}>
                    <div className="HeaDer">
                         <Title style={{color: 'white'}}>Hi! I'm a Software Developer</Title>
                    </div>
                    <p id="text">My name is Steven Ly. I am an avid enthusiast in technology and how it transforms our lives everyday. When I am not learning programming, I like to read up on new technologies, do coding challenges or play video games.</p>  
               </div>
               <Button type="primary" icon="mail" size="large"><a href="mailto:steven.ly369@gmail.com" style={{color: 'white'}}> Contact</a></Button>
          </section>
     );
  }
}

export default MainText;